<template>
  <el-row type="flex" justify="center" ref="setPasswordCard">
    <el-col :span="spanValue">
      <card class="set-password-card generic-card">
        <template slot="title">
          <h2>{{$t('set') + " " + $t('Comn_new_password')}}</h2>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            type="error"
            @close="ehm__handleAlertClose"
          >
          <i18n :path="ehm__unhandledErrorMessage" tag="span">
            <template v-slot:action>
              <a href="/resend-link">{{ $t("get_new_link") }}</a>
            </template>
          </i18n>
          </el-alert>
          <er-form
            :ref="formName"
            :model="user"
            v-loading="loading"
            element-loading-background="white"
            size="small"
            :element-loading-text="$t('Comn_auth_loading')"
          >
            <div class="form-field-label">
              {{ $t("set_new_password_user_request") }}
            </div>
            <div class="user-properties-element">
              <er-form-item
                prop="password"
                :error="
                  aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
                "
              >
                <er-input
                  v-model="user.password"
                  class="er-input--password"
                  :placeholder="$t('Usrs_enter_pwd')"
                  ref="passwordField"
                  @input="pvm__getPercentage"
                  show-password
                  :maxlength="userFieldLengths.max_password_length"
                  :style="
                    `--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`
                  "
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <er-form-item
                prop="confirm_password"
                :error="
                  aekmm__castErrorKeyToLang(
                    ehm__errMessagesObject.confirm_password
                  )
                "
              >
                <er-input
                  v-model="user.confirm_password"
                  class="input-box"
                  :placeholder="`${$t('Usrs_enter_confirm_pwd')}`"
                  :maxlength="userFieldLengths.max_password_length"
                  @keyup.native.enter="sumbitSetPasswordDetails"
                  show-password
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
            </div>
            <el-row class="password-validations-container">
              <span class="header">
                {{ $t("Usrs_pwd_requirement_header") }}
              </span>
              <ul class="validation-rules">
                <li
                  v-for="(validation, id) in pvm__passwordValidations"
                  :key="id"
                  :class="[
                    'validation-rules-row',
                    validation.validator() && 'is-checked'
                  ]"
                >
                  <div class="title">
                    <p>{{ ftm__capitalize(validation.content) }}</p>
                  </div>
                </li>
              </ul>
            </el-row>
          </er-form>
          <layout-toolbar class="set-password-layout-tool-bar" justify="center">
            <er-button
              class="btn_submit"
              size="mini"
              @click="sumbitSetPasswordDetails"
            >
              {{ $t("Comn_save") }}
            </er-button>
          </layout-toolbar>
        </template>
      </card>
    </el-col>
  </el-row>
</template>
<script>
  import User from "@/model/user.js";
  import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
  import redirectsMixin from "@/mixins/redirectsMixin.js";
  import filtersMixin from "@/mixins/filtersMixin.js";
  import passwordValidationMixin from "../../mixins/passwordValidationMixin.vue";
  import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
  import card from "./card";
  import {
    erButton,
    erForm,
    erInput,
    erFormItem
  } from "@/components/base/basePondlogs.js";
  import { mapActions } from "vuex";
  import fieldLengths from '../../constants/fieldLengths';

  export default {
    mixins: [
      errorHandlerMixin,
      redirectsMixin,
      authErrorKeyMapMixin,
      filtersMixin,
      passwordValidationMixin
    ],
    data: function() {
      return {
        userFieldLengths: fieldLengths,
        user: new User(),
        ehm__errMessagesObject: new User(),
        loading: false,
        formName: "setPasswordForm"
      };
    },
    components: {
      erButton,
      erForm,
      erFormItem,
      erInput,
      card
    },
    mounted() {
      if (!this.$route.params.vc) {
        this.$router.push("/sign-in");
      }
      this.setRefsForPasswordValidations(
        this.$refs.setPasswordCard.$el,
        this.$refs.passwordField
      );
    },
    methods: {
      ...mapActions("auth", {
        setNewPassword: "setNewPassword"
      }),
      navigate(component) {
        this.$router.push("/" + component);
      },
      resetFields() {
        this.user = new User();
      },
      ehm__error403Handler: function(err) {
        const errObject = {
          INVALID_URL: 'INVALID_SET_PASSWORD_TOKEN',
          URL_EXPIRED: 'EXPIRED_SET_PASSWORD_TOKEN'
        }
        this.ehm__unhandledErrorMessage = errObject[err.response.data.error_code];
      },
      ehm__error409Handler: function(err) {
        const errObject = {
        PASSWORD_CREATED_ALREADY: 'please-choose-the-new-password-other-than-current-password'
      }
      this.ehm__unhandledErrorMessage = errObject[err.response.data.error_code];
      },
      async sumbitSetPasswordDetails() {
        try {
          this.ehm__unhandledErrorMessageV2 = null;
          this.loading = true;
          const requestObj = this.$route.params;
          this.ehm__errMessagesObject = new User();
          requestObj.password = this.user.password;
          requestObj.confirm_password = this.user.confirm_password;
          await this.setNewPassword(requestObj);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("successfully-setted-your-new-password"),
            duration: 5000,
            type: "success"
          });
          this.$router.push("/sign-in");
        } catch (err) {
          this.ehm__errorMessages(err, false);
        } finally {
          this.btnSubmitLoading = false;
          this.loading = false;
        }
      }
    }
  };
</script>
<style lang="scss">
  .set-password-card {
    height: 100%;
    .set-password-layout-tool-bar {
      min-width: 300px;
    }
    .general-err-msg {
      font-size: 13px;
      text-align: center;
      color: red;
    }
    .el-tag {
      font-size: 1.8vh;
    }
    .el-form {
      text-align: center;
    }
    .el-form-item .el-form-item__content {
      margin: 0px !important;
    }
    .user-properties-element {
      width: 100%;
      text-align: center;
      display: inline-block;
    }
    .password-validations-container {
      margin-bottom: 40px;
      padding: 0 10px;
      .validation-rules .validation-rules-row {
        flex-basis: 50%;
      }
    }
  }
</style>
